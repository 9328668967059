$top-main-gap: 64px;
$dirty-top-main-gap: 56px;
$dirty-main-gap: $top-main-gap - 8;

:root {
	--bs-font-sans-serif: Roboto, system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
		'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	--bs-font-monospace: 'Roboto Mono', SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
		monospace;
	--bs-font-serif: 'Lora', serif;
	// --primary-dark-text: rgba(0, 0, 0, 0.87);
	// --secondary-dark-text: rgba(0, 0, 0, 0.54);
	// --disabled-dark-text: rgba(0, 0, 0, 0.38);
	// --divider-dark-text: rgba(0, 0, 0, 0.12);
	// --primary-light-text: #fff;
	// --secondary-light-text: rgba(255, 255, 255, 0.7);
	// --disabled-light-text: rgba(255, 255, 255, 0.5);
	// --divider-light-text: rgba(255, 255, 255, 0.12);
	--cool-text-color: #495057;
	--transition-duration: 0.25s;

	--white-glass-color: rgba(255, 255, 255, 0.8);
	--dark-glass-color: rgba(0, 0, 0, 0.5);

	--highlight-color: var(--bs-warning-text-emphasis);
	--highlight-background-color: var(--bs-warning-bg-subtle);
	--highlight-border-color: var(--bs-warning-border-subtle);
	--stripped-row-color: var(--bs-light);
}

[data-bs-theme='dark'] {
	--project-card-mix-percent: 40%;

	--cool-text-color: var(--bs-emphasis-color);
	--auxiliary-image-opacity: 0.75;

	--highlight-color: var(--bs-warning-text-emphasis);
	--highlight-background-color: var(--bs-warning-bg-subtle);
	--highlight-border-color: var(--bs-warning-border-subtle);
	--stripped-row-color: #2c3034;

	--white-glass-color: rgba(0, 0, 0, 0.5);
	--dark-glass-color: rgba(255, 255, 255, 0.8);

	--bs-dark-rgb: 23, 27, 31;

	.table-themed {
		--bs-table-color: var(--bs-body-color);
		--bs-table-bg: #212529;
		--bs-table-border-color: #373b3e;
		--bs-table-striped-bg: #2c3034;
		--bs-table-striped-color: var(--bs-body-color);
		--bs-table-active-bg: #373b3e;
		--bs-table-active-color: var(--bs-body-color);
		--bs-table-hover-bg: #323539;
		--bs-table-hover-color: var(--bs-body-color);
		color: var(--bs-table-color);
		border-color: var(--bs-table-border-color);
	}

	.themed-select__control,
	.themed-select__menu {
		background-color: var(--bs-body-bg) !important;
	}
	.themed-select__single-value,
	.themed-select__input-container,
	.themed-select__multi-value__label {
		color: var(--bs-body-color) !important;
	}
	.themed-select__multi-value {
		color: var(--bs-body-color) !important;
		background-color: var(--bs-secondary-bg) !important;
	}
	.themed-select__multi-value__remove:hover {
		background-color: rgba(255, 0, 0, 0.2);
	}
	.themed-select__option--is-focused {
		color: var(--bs-body-color) !important;
		background-color: var(--bs-secondary-bg) !important;
	}
	.themed-select__option--is-selected {
		color: var(--bs-body-bg) !important;
		background-color: var(--bs-primary) !important;
	}
}

.with-button {
	.themed-select__control {
		border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
		border-right: none;
	}
}

%bg-default {
	background-color: var(--bs-body-bg);
}
.bg-default {
	@extend %bg-default;
}

%default-font,
%sans-serif-font {
	font-family: var(--bs-font-sans-serif);
}

%serif-font {
	font-family: var(--bs-font-serif);
}
.font-serif {
	@extend %serif-font;
}

textarea {
	resize: none;
}

.text-cool {
	color: var(--cool-text-color);
}

.vh-75 {
	height: 75vh;
}
.vw-38 {
	width: 38vw;
}

.w-38 {
	width: 38% !important;
}

.min-width-0 {
	min-width: 0;
}

.min-height-25vh {
	min-height: 25vh !important;
}
.min-height-38vh {
	min-height: 38vh !important;
}

@keyframes keyframe-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.animation-spin {
	animation: keyframe-spin 1s infinite linear;
	// display: inline-block;
}

@keyframes keyframe-bounce {
	0% {
		transform: scale(0.62);
	}
	50% {
		transform: scale(1.61);
	}
	100% {
		transform: scale(0.62);
	}
}
.animation-bounce {
	animation: keyframe-bounce 2s infinite linear;
	display: inline-block;
}

@keyframes keyframes-highlight {
	0% {
		background-color: var(--bs-warning, gold);
	}
	100% {
		background-color: transparent;
	}
}
.animation-highlighted {
	animation: 1s linear alternate 0.1s 1 keyframes-highlight;
}

@keyframes preloaderAnimation {
	0% {
		transform: scale(0);
	}
	50% {
		transform: scale(1);
		background-color: var(--bs-gray-500);
	}
	100% {
		transform: scale(0);
	}
}

body {
	@extend %bg-default;
	@extend %default-font;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.not-auth {
	#root {
		background-color: #010c33;
		background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='456' height='456' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23030644' stroke-width='2.6'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23323355'%3E%3Ccircle cx='769' cy='229' r='6'/%3E%3Ccircle cx='539' cy='269' r='6'/%3E%3Ccircle cx='603' cy='493' r='6'/%3E%3Ccircle cx='731' cy='737' r='6'/%3E%3Ccircle cx='520' cy='660' r='6'/%3E%3Ccircle cx='309' cy='538' r='6'/%3E%3Ccircle cx='295' cy='764' r='6'/%3E%3Ccircle cx='40' cy='599' r='6'/%3E%3Ccircle cx='102' cy='382' r='6'/%3E%3Ccircle cx='127' cy='80' r='6'/%3E%3Ccircle cx='370' cy='105' r='6'/%3E%3Ccircle cx='578' cy='42' r='6'/%3E%3Ccircle cx='237' cy='261' r='6'/%3E%3Ccircle cx='390' cy='382' r='6'/%3E%3C/g%3E%3C/svg%3E");
		// background-attachment: fixed;
		// background-size: cover;
	}
}

#root {
	padding-top: $top-main-gap;
}

.fi.country-flag {
	width: var(--country-flag-width, 1.53em);
	height: var(--country-flag-height, 1.1475em);
	background-size: cover;
	// box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
	&.fis {
		--country-flag-width: 1em;
		--country-flag-height: 1em;
	}
}

.logout__container {
	.logout__user-name {
		max-width: 0;
		transition: all var(--transition-duration) linear 0.6s;
		opacity: 0;
	}
	&:hover {
		.logout__user-name {
			max-width: 200px;
			opacity: 1;
		}
	}
}

.top-main {
	top: $top-main-gap;
}

.icon-lg {
	scale: 138%;
}
.icon-xl {
	scale: 162%;
}

.mt-n1 {
	margin-top: -0.25rem !important;
}
.mt-n2 {
	margin-top: -0.5rem !important;
}
.my-n1 {
	margin-top: -0.25rem !important;
	margin-bottom: -0.25rem !important;
}
.ms-n1 {
	margin-left: -0.25rem !important;
}
.me-n1 {
	margin-right: -0.25rem !important;
}

.show-only-child:not(:only-child) {
	display: none;
}

.white-glass {
	background-color: var(--white-glass-color);
	// color: var(--bs-primary-text-emphasis);
	color: var(--bs-emphasis-color);
}
.dark-glass {
	background-color: var(--dark-glass-color);
	color: var(--bs-secondary-bg);
}

.text-small {
	font-size: 0.8em;
}

@media screen and (max-width: 576px) {
	div {
		--grid-auto-fill-width: 300px;
	}
}

.entity-icon {
	width: var(--entity-size, 1em);
	height: var(--entity-size, 1em);
	// padding: 0.25em;
	object-fit: cover;
}

.project-card {
	background-color: var(--project-card-bg-color);
	color: var(--project-card-text-color, var(--bs-emphasis-color));
}
.project-card_danger {
	--project-card-bg-color: RGB(var(--bs-danger-rgb));
	--project-card-text-color: #fff;
}
.project-card_warning {
	--project-card-bg-color: RGB(var(--bs-warning-rgb));
	--project-card-text-color: #000;
}
.project-card_success {
	--project-card-bg-color: RGB(var(--bs-success-rgb));
	--project-card-text-color: #fff;
}
.project-card_primary {
	--project-card-bg-color: RGB(var(--bs-primary-rgb));
	--project-card-text-color: #fff;
}
.project-card_secondary {
	--project-card-bg-color: RGB(var(--bs-secondary-rgb));
	--project-card-text-color: #fff;
}

.is-invalid {
	color: var(--bs-danger);
	background-color: var(--bs-danger-bg-subtle);
}

.scheme-container {
	left: 0.75rem;
	top: 100px;
	right: 0.75rem;
	bottom: 80px;
}

.opacity-without-hover {
	opacity: var(--owh-opacity, 0.5);
	transition: opacity var(--transition-duration) ease 0.25s;
	&:hover {
		--owh-opacity: 1;
	}
}

.bg-slight-darker {
	background-color: rgba(0, 0, 0, 0.2);
	/* --bs-badge-color: var(--bs-secondary-color); */
	--bs-badge-color: var(--bs-emphasis-color);
}

.card-in-project {
	// --card-bg: var(--bs-success-bg-subtle);
	--card-bg: var(--bs-warning);
}

.grid-whole-row {
	grid-column: 1/-1;
}

// .filter-shadow-sm {
// 	filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
// }

.in-project-path {
	color: color-mix(in lch, var(--bs-warning), rgba(0, 0, 0, var(--in-project-patch-opacity, 0.7)));
}

a.in-project-path {
	&:hover {
		--in-project-patch-opacity: 0.6;
	}
}

.double-modal-backdrop {
	--bs-backdrop-opacity: 0;
	// --bs-backdrop-zindex: 1055;
	& + .modal.show {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

// .modal-90w {
// 	@media (min-width: 1400px) {
// 		max-width: 1500px !important;
// 		width: 90%;
// 	}
// }

.btn-with-no-size {
	--bs-btn-font-size: unset;
}

.white-halo {
	filter: drop-shadow(0 0 1px white);
}

.img-zoom-able {
	cursor: zoom-in;
}

.modal-zoom {
	$w: 90vw;
	$h: 90vh;
	max-width: $w !important;
	max-height: $h !important;
	cursor: zoom-out;
	.modal-content {
		background-color: transparent;
		border: none;
		img {
			max-width: $w;
			max-height: $h;
			object-fit: contain;
			background-color: #fff;
			box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
		}
	}
}

.rel-image__item {
	flex: 0 0 15%;
	min-width: 150px;
}

.link-button {
	background-color: transparent;
	border: none;
	margin: 0;
	padding: 0;
}
